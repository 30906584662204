import React from 'react';
import { css } from 'aphrodite/no-important';

import { styleSheet } from './styles';
export interface footerInterface {
  data: any;
  styles: any;
}

const Footer = (props: footerInterface) => {
  const { data, styles } = props;
  const style = styleSheet(styles);

  return (
    <footer className={css(style.footer) + " footer-section"}>
      <div className={css(style.container)}>
        {data && <span className={css(style.copy)} dangerouslySetInnerHTML={{__html: data.copy.copyright}}></span>}
        {data && <a className={css(style.copy)} href={data.copy.privacy.link} target="_blank" dangerouslySetInnerHTML={{__html: data.copy.privacy.copy}}></a>}
      </div>
    </footer>
  );
}

export default Footer;
