import merge from 'lodash/merge';
import { StyleSheet } from 'aphrodite/no-important';

import { IGenericObject } from '../../models/base';
import { children, generateCmsStyles, media } from '../../utils/styles';

export const styleSheet: any = (styles: IGenericObject) => {
  const baseStyles: any = {
    winner: {
      margin: "15px auto",
      textTransform: "uppercase",
      textAlign: "center"
    },

    card: {
      color: "#F2FCFF",
      margin: "auto",
      padding: 15,
      maxWidth: 280,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      boxSizing: "border-box",

      [media.phablet]: {
        padding: 25,
        maxWidth: 470
      }
    },

    headline: {
      color: "#A78BDD",
      display: "none",
      fontWeight: 500,

      [media.phablet]: {
        fontSize: 35,
        display: "block",
      },

      [media.desktop]: {
        fontSize: 40
      }
    },

    image: {
      width: 251,
      height: 251,
      margin: "auto",

      [media.phablet]: {
        width: 420,
        height: 425
      },

      [children('img')]: {
        boxShadow: "0 12px 10px 0px #000",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        overflow: "hidden"
      }
    },

    name: {
      margin: "18px auto 0 auto",
      background: "linear-gradient(90deg, rgba(91,59,125,1) 0%, rgba(200,210,247,1) 100%)",
      width: 251,
      minHeight: 62,
      boxSizing: "border-box",
      padding: "10px 5px",

      [media.phablet]: {
        width: 420,
        minHeight: 104,
        padding: "15px 5px",
      }
    },

    characterName: {
      margin: 0,
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "20px",

      [media.phablet]: {
        fontSize: 30,
        lineHeight: "30px"
      }
    },

    winnerName: {
      margin: 0,
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "30px",

      [media.phablet]: {
        fontSize: 45,
        lineHeight: "45px"
      }
    },

    button: {
      textDecoration: "none",
      background: "#29094A",
      color: "#F2FCFF",
      fontSize: 15,
      fontWeight: 300,
      border: "none",
      textTransform: "uppercase",
      width: 158,
      height: 25,
      cursor: "pointer",
      margin: "10px auto 0 auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      ':disabled': {
        color: "rgba(242,252,255,0.25)",
        cursor: "default"
      },

      [media.phablet]: {
        fontSize: 30,
        width: 320,
        height: 51,
        margin: "29px auto 0 auto",
      }
    }
  };

  const cmsStyles: any = {
    ...generateCmsStyles(styles),
  };

  return StyleSheet.create(merge(baseStyles, cmsStyles));
};

