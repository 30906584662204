import merge from 'lodash/merge';
import { StyleSheet } from 'aphrodite/no-important';

import { IGenericObject } from '../../models/base';
import { children, generateCmsStyles, media } from '../../utils/styles';

export const styleSheet: any = (styles: IGenericObject) => {
  const baseStyles: any = {
    banner: {
      background: "#0c142c",

      [children('img')]: {
        maxWidth: "1100px",
        margin: "0 auto",
        width: "100%"
      }
    },

    image_mobile: {
      display: "block",
      [media.phablet]: {
        display: "none"
      },
      [media.desktop]: {
        display: "none"
      }
    },

    image_tablet: {
      display: "none",
      [media.phablet]: {
        display: "block"
      },
      [media.desktop]: {
        display: "none"
      }
    },

    image_desktop: {
      display: "none",
      [media.phablet]: {
        display: "none"
      },
      [media.desktop]: {
        display: "block"
      }
    }

  };

  const cmsStyles: any = {
    ...generateCmsStyles(styles),
  };

  return StyleSheet.create(merge(baseStyles, cmsStyles));
};

