export const getQSPs = () => {
  const qsps = Object.create(null);

  window.location.search
    .slice(1)
    .split('&')
    .forEach((item) => {
      const [key, value = ''] = item.split('=');
      qsps[key] = value;
    });

  return qsps;
};

/**
 * Evaluates if a string has a valid e-mail format.
 * @param string - The string value to evaluate
 */
export const isValidEmail = (string: string): boolean => {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test( string );
}

/**
 * Creates a cookie for given name and value.
 * Default lifetime is 1 year.
 */
 export const createCookie = ( name: string, value: any, days: number = 1 * 365 ) => {
	var expires = '';

	if ( days ) {
		var date = new Date();
		date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );
		expires = '; expires=' + date.toUTCString();
	}

	document.cookie = name + '=' + value + expires + '; path=/';
}

/**
 * Retreives a cookie by a given name
 */
export const readCookie = ( name: string ): string => {
	var nameEQ = name + '=';
	var ca = document.cookie.split( ';' );

	for ( var i = 0; i < ca.length; i++ ) {
		var c = ca[ i ];
		while ( c.charAt( 0 ) === ' ' ) c = c.substring( 1, c.length );
		if ( c.indexOf( nameEQ ) === 0 ) return c.substring( nameEQ.length, c.length );
	}

	return '';
}

/**
 * Erases a cookie by a given name
 */
export const eraseCookie = ( name: string ): void => {
	createCookie( name, '', -1 );
}
