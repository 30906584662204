import React, { useContext, useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import useLocalStorage from '@telescope/react-hooks/useLocalStorage';

import { styleSheet } from './styles';
import { UserContext } from '../../utils/context';
import { apiService } from '../../utils/api';
import { COOKIE_USER, STATUS } from '../../utils/constants';
import { createCookie, readCookie } from '../../utils/helpers';

export interface gridInterface {
	cards: any[];
	data: any;
	styles: any;
	nominee: any;
  updateUser: Function;
}

export interface cardInterface {
	data: any;
	copy: any;
	styles: any;
	userGuess: string;
  updateUser: Function;
  setUserVotes: any;
  userVotes: any;
}

const Card = ( props: cardInterface ) => {
	const {data, copy, styles, userGuess, updateUser, userVotes, setUserVotes} = props;
	const user = useContext( UserContext );
	const style = styleSheet( styles );
	const input: any = React.createRef();
	const [editEnabled, setEditEnabled] = useState( false );
	const [saveEnabled, setSaveEnabled] = useState( false );
	const [guessText, setGuessText] = useState( userGuess );
	const [unsavedGuess, setUnsavedGuess] = useState( '' );

	const onSave = async ( e: any, id: string ) => {
    e.preventDefault();
		setEditEnabled( false );
		setSaveEnabled( false );
		await apiService.sendVote( id, guessText, user.email );
    
    // Update localStorage with new vote array.
    let newVotes;
    if (!userVotes || userVotes === "") {
      newVotes = {};
    } else {
      newVotes = userVotes;
    }
    newVotes[id] = guessText;
    setUserVotes(newVotes);
	};

	const onEdit = (e: any) => {
    e.preventDefault();

		if ( editEnabled ) {
			// Cancel edit
			input.current.value = unsavedGuess;
			setSaveEnabled( false );
		} else {
			// Enable edit
			setUnsavedGuess( input.current.value );
		}
		setEditEnabled( !editEnabled );
	};

	const onGuess = ( event: any ) => {
		if ( event?.target?.value.length > 0 && event?.target?.value.replace(/\s/g, '').length) {
			setGuessText( event.target.value );
			setSaveEnabled( true );
		} else {
			setSaveEnabled( false );
    }
	};

  const activateLogin = () => {
    updateUser({...user, loginSection: true});
  }

  useEffect(() => {
    if (editEnabled) {
      input.current.focus();
    }
  }, [editEnabled]);

	// User logged out
	const fullyDisableHidden = (
		<div>
			<p className={css( style.cardHeader )}>{copy.copy.active_cta}</p>
			<input className={css( style.cardInput )}
				   type="text"
				   placeholder={copy.copy.placeholder_text}
				   disabled={true}
				   defaultValue={userGuess}/>
			<div className={css( style.cardActions )}>
				<button className={css( style.guessButton )} onClick={activateLogin}>{copy.buttons.edit.copy}</button>
				<button className={css( style.guessButton )} disabled={true}>{copy.buttons.save.copy}</button>
			</div>
		</div>);

	const fullyDisableRevealed = (
		<div className={css( style.cardRevealed )}>
			<p className={css( style.cardHeader )}>{data.inactive_name}</p>
			<input className={css( style.cardInput )} type="text" placeholder="" value="" disabled={true}/>
			<div className={css( style.cardActions )}>
				<a className={css( style.watchButton )}
				   target='_blank'
				   href={data.inactive_link}>{copy.buttons.inactive.copy}</a>
			</div>
		</div>);

	// User logged in
	const defaultHidden = (
		<div>
			<p className={css( style.cardHeader )}>{copy.copy.active_cta}</p>
      <form>
        <input onInput={onGuess}
            className={css( style.cardInput )}
            type="text"
            placeholder={copy.copy.placeholder_text}
            disabled={!editEnabled}
            maxLength={20}
            ref={input}
            defaultValue={userGuess}
        />
        <div className={css( style.cardActions )}>
            <div onClick={onEdit}
                className={css( style.guessButton )}>{editEnabled ? copy.buttons.cancel.copy : copy.buttons.edit.copy}</div>
            <button type="submit" onClick={(e) => onSave( e, data.id )} className={css( style.saveButton )}
                disabled={!saveEnabled}>{copy.buttons.save.copy}</button>
        </div>
      </form>
		</div>);

	const defaultRevealed = (
		<div className={css( style.cardRevealed )}>
			<p className={css( style.cardHeader )}>{data.inactive_name}</p>
			<input className={css( style.cardInput )} type="text" placeholder={copy.copy.placeholder_text}
				   value={userGuess === "" ? " " : userGuess}
				   disabled={true}/>
			<div className={css( style.cardActions )}>
				<a className={css( style.watchButton )}
				   target='_blank'
				   href={data.inactive_link}>{copy.buttons.inactive.copy}</a>
			</div>
		</div>);

	return (
		<div className={css( style.card )}>
			<div className={css( style.cardInfo )}>
				<div className={css( style.cardImage )}>
					<img src={data.active === STATUS.ACTIVE ? data.image : data.inactive_image} alt="Character image"/>
				</div>
				<p className={css( style.cardName )}>{data.name}</p>
			</div>
			{!user.active ? (data.active === STATUS.ACTIVE ? fullyDisableHidden : fullyDisableRevealed) : (data.active === STATUS.ACTIVE ? defaultHidden : defaultRevealed)}
		</div>
	);
};

const Grid = ( props: gridInterface ) => {
	const {cards, data, styles, nominee, updateUser} = props;
	const user: any = useContext( UserContext );
	const style = styleSheet( styles );
  const [userVotes, setUserVotes] = useLocalStorage<any>('userVotes', false);

	return (
		<section className={css( style.grid )}>
			{cards && nominee && cards.map( ( card, index ) => {
				let userGuess = '';
				if ( userVotes[ card.id ] ) {
					userGuess = userVotes[ card.id ]
				}
				return (
					<Card userVotes={userVotes} setUserVotes={setUserVotes} updateUser={updateUser} data={card} copy={nominee} styles={styles} key={index} userGuess={userGuess}/>
				);
			} )}
		</section>
	);
}

export default Grid;
