import merge from 'lodash/merge';
import { StyleSheet } from 'aphrodite/no-important';

import { IGenericObject } from '../../models/base';
import { generateCmsStyles, media } from '../../utils/styles';

export const styleSheet: any = (styles: IGenericObject) => {
  const baseStyles: any = {
    footer: {
      background: "#0c142c"
    },
    container: {
      maxWidth: "975px",
      width: "100%",
      display: "flex",
      margin: "0 auto",
      padding: "25px 20px 30px 20px",
      justifyContent: "space-between",
      background: "linear-gradient(rgb(95, 62, 138) 0%, rgb(14, 1, 25) 100%)",
      boxSizing: "border-box",
      fontWeight: 300
    },
    copy: {
      color: "#ffffff",
      fontSize: 13,

      [media.desktop]: {
        fontSize: 16
      },
    }
  };

  const cmsStyles: any = {
    ...generateCmsStyles(styles),
  };

  return StyleSheet.create(merge(baseStyles, cmsStyles));
};

