export const DEFAULT_WID = '11408058675853c6';
export const DEFAULT_STYLES_WID = '5180c058f7a8234f';
export const DEFAULT_POLL_RATE = 30000;
export const BASE_SECRET_KEY = 'cwAe4zySs1EpXbMGI';
export const GA_EVENTS = {
	INIT_APP: {
		category: 'load',
		action: 'app',
		label: ''
	}
};
export const COOKIE_USER = {
  name: "user_votes",
  expiration: 90
};

export const STATUS = {
	ACTIVE: "1",
	INACTIVE: "0"
};
