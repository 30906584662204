import merge from 'lodash/merge';
import { StyleSheet } from 'aphrodite/no-important';

import { IGenericObject } from '../../models/base';
import { children, generateCmsStyles, media } from '../../utils/styles';

export const styleSheet: any = (styles: IGenericObject) => {
  const baseStyles: any = {
    grid: {
      color: "#F2FCFF",
      display: "flex",
      flexWrap: "wrap",
      margin: "9px auto",
      maxWidth: 225,

      [media.phablet]: {
        maxWidth: 460,
        margin: "20px auto",
      },

      [media.desktop]: {
        maxWidth: 755
      }
    },

    card: {
      margin: "9px 0",
      padding: "12px 20px 5px 20px",
      textTransform: "uppercase",
      width: "100%",
      background: "linear-gradient(0deg, rgba(91,59,125,1) 0%, rgba(200,210,247,1) 100%)",

      [media.phablet]: {
        width: "calc(calc(100%/2) - 54px)",
        margin: 9,
        padding: "19px 18px 10px 18px"
      },

      [media.desktop]: {
        width: "calc(calc(100%/4) - 48px)",
        padding: 15
      }
    },

    cardInfo: {
      backgroundColor: "#29094A",
      textAlign: "center",
    },

    cardImage: {
      padding: "8px 17px 5px 17px",
      width: 152,
      height: 154,

      [media.phablet]: {
        width: 160,
        height: 162,
        padding: "13px 8px 7px 8px"
      },

      [media.desktop]: {
        width: 126,
        height: 127,
        padding: "10px 7px 5px 7px"
      },

      [children('img')]: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        overflow: "hidden"
      },
    },

    cardName: {
      padding: "0 5px",
      margin: 0,
      fontSize: 18,
      lineHeight: "40px",
      fontWeight: 500,
      minHeight: 45,

      [media.phablet]: {
        fontSize: 14,
        lineHeight: "28px",
        minHeight: 35
      },

      [media.desktop]: {
        fontSize: 14,
        lineHeight: "28px"
      }
    },

    cardHeader: {
      fontWeight: 700,
      fontSize: 18,
      textAlign: "center",
      margin: "5px 0 10px 0",

      [media.phablet]: {
        fontSize: 14,
        margin: "10px 0 16px 0"
      },

      [media.desktop]: {
        fontSize: 14
      },
    },

    cardInput: {
      background: "transparent",
      color: "#F2FCFF",
      textAlign: "center",
      fontSize: 18,
      fontWeight: 300,
      textTransform: "uppercase",
      width: "100%",
      border: "none",
      borderBottom: "1px solid #F2FCFF",
      padding: 5,
      boxSizing: "border-box",
      borderRadius: "0",
      '::placeholder': {
        color: "rgba(242,252,255,0.51)"
      },

      ':disabled': {
        opacity: ".5"
      },

      [media.phablet]: {
        fontSize: 14
      },

      [media.desktop]: {
        fontSize: 14
      }
    },

    cardRevealed: {
      [children('input')]: {
        ':disabled': {
          opacity: 1
        },
      }
    },

    cardActions: {
      marginTop: 15,
      display: "flex",
      justifyContent: "space-between",

      [media.phablet]: {
        fontSize: 12
      },

      [media.desktop]: {
        marginTop: 10
      }
    },

    saveButton: {
      background: "#29094A",
      color: "#F2FCFF",
      fontSize: 16,
      fontWeight: 300,
      border: "none",
      textTransform: "uppercase",
      width: 84,
      height: 31,
      cursor: "pointer",

      ':disabled': {
        color: "rgba(242,252,255,0.25)",
        cursor: "default"
      },

      [media.phablet]: {
        fontSize: 14,
        width: 70,
        height: 25,
      },

      [media.desktop]: {
        fontSize: 10,
        width: 60,
        height: 23
      }
    },

    guessButton: {
      background: "#29094A",
      color: "#F2FCFF",
      fontSize: 16,
      fontWeight: 300,
      border: "none",
      textTransform: "uppercase",
      width: 84,
      height: 26,
      cursor: "pointer",
      textAlign: "center",
      paddingTop: 5,

      ':disabled': {
        color: "rgba(242,252,255,0.25)",
        cursor: "default"
      },

      [media.phablet]: {
        fontSize: 14,
        width: 70,
        height: 21,
        paddingTop: 4
      },

      [media.desktop]: {
        fontSize: 10,
        width: 60,
        height: 18,
        paddingTop: 5,
      }
    },

    watchButton: {
      textDecoration: "none",
      background: "#29094A",
      color: "#F2FCFF",
      fontSize: 16,
      fontWeight: 300,
      border: "none",
      textTransform: "uppercase",
      width: 189,
      height: 31,
      cursor: "pointer",
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      ':disabled': {
        color: "rgba(242,252,255,0.25)",
        cursor: "default"
      },

      [media.phablet]: {
        fontSize: 14,
        width: 176,
        height: 25,
      },

      [media.desktop]: {
        fontSize: 10,
        width: 125,
        height: 20
      }
    }
  };

  const cmsStyles: any = {
    ...generateCmsStyles(styles),
  };

  return StyleSheet.create(merge(baseStyles, cmsStyles));
};

