import React from 'react';
import { css } from 'aphrodite/no-important';

import { styleSheet } from './styles';

export interface headerInterface {
  data: any;
  styles: any;
}

const Closed = (props: headerInterface) => {
  const { data, styles } = props;
  const style = styleSheet(styles);

  return (
    <section className={css(style.container)}>
      {data && <h1 className={css(style.headline)}>{data.copy.headline}</h1>}
      {data && <h4 className={css(style.subHeadline)}>{data.copy.subheadline}</h4>}
    </section>
  );
}

export default Closed;
