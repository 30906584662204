import React, { useContext, useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import useLocalStorage from '@telescope/react-hooks/useLocalStorage';

import { styleSheet } from './styles';
import { UserContext, userConfig, userState } from '../../utils/context';
import { apiService } from '../../utils/api';
import { createCookie, readCookie, eraseCookie, isValidEmail } from '../../utils/helpers';
import { COOKIE_USER } from '../../utils/constants';

export interface loginInterface {
  data: any;
  styles: any;
  updateUser: Function;
  appLoaded: boolean;
}

const Login = (props: loginInterface) => {
  const { data, styles, appLoaded } = props;
  const style = styleSheet(styles);
  const user = useContext(UserContext);
  const [loginVisible, setLoginVisible] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const [formTerms, setFormTerms] = useState(data && data.terms.settings.preChecked === "true");
  const [formOptIn, setFormOptIn] = useState(data && data.optIn.settings.preChecked === "true");
  const [formEmailError, setFormEmailError] = useState(false);
  const [formTermsError, setFormTermsError] = useState(false);
  const [formOptInError, setFormOptInError] = useState(false);
  const [userVotes, setUserVotes] = useLocalStorage<any>('userVotes', false);

  const handleSwitcher = () => {
    if (user.active) {
      eraseCookie(COOKIE_USER.name);
      props.updateUser(userConfig.loggedOut);
      setLoginVisible(false);
      setFormEmailError(false);
      setFormTermsError(false);
      setFormOptInError(false);
      setFormEmail("");
      setFormTerms(data && data.terms.settings.preChecked === "true");
      setFormOptIn(data && data.optIn.settings.preChecked === "true");
    } else {
      // This case is for displaying the conditional visibility of the login form.
      setLoginVisible(!loginVisible);
    }
  };

  const logUser = async (email = formEmail) => {
    const voteResponse = await apiService.getVotes(email);
    const newUser: userState = {
      ...userConfig.loggedIn,
      votes: !voteResponse || voteResponse.votestring === "" ? [] : JSON.parse(voteResponse.votestring),
      email: email
    };
    props.updateUser(newUser);
  };

  const handleLogin = async () => {
    const emailError = formEmail === '' || !isValidEmail(formEmail);
    const termsError = data.terms.settings.required === "true" && !formTerms;
    const optInError = data.optIn.settings.required === "true" && !formOptIn;

    setFormEmailError( emailError );
    setFormTermsError( termsError );
    setFormOptInError( optInError );

    if ( emailError || termsError || optInError ) return;

    createCookie(COOKIE_USER.name, formEmail, COOKIE_USER.expiration);
    await logUser();
    setLoginVisible(false);
  };

  const handleFormChange = (event: any) => {
    switch (event.target.name) {
      case "email":
        setFormEmail(event.target.value);
        setFormEmailError(formEmailError && (event.target.value === "" || !isValidEmail(event.target.value)));
        break;
      case "terms":
        setFormTerms(event.target.checked);
        setFormTermsError(formTermsError && data.terms.settings.required && !event.target.checked);
        break;
      case "optIn":
        setFormOptIn(event.target.checked);
        setFormOptInError(formOptInError && data.optIn.settings.required && !event.target.checked);
        break;
      default:
    }
  };

  const getUserVotes = () => {
    if (userVotes) {
      const newUser: userState = {
        ...userConfig.loggedIn,
        votes: userVotes
      };
      props.updateUser(newUser);
    }
  }

  useEffect( () => {
    if (user.loginSection && !loginVisible) {
      // Uncomment this to activate login section.
      // setLoginVisible(true);
    }
  }, [user]);

  useEffect( () => {
    // Uncomment this section to activate getting user votes from ruleset.
    if (!appLoaded) return;
    getUserVotes();
    
    /*
    // Check for user login status after application loads.
    if (!appLoaded) return;
    const userLogin = readCookie(COOKIE_USER.name);
    if (userLogin && userLogin.length > 0) {
      logUser(userLogin);
    }
    */
  }, [appLoaded]);

  return (
    <section className={css(style.container)}>
      {false && <div className={css(style.userContainer)}>
        {user.active && <span className={css(style.userWelcome)}>Welcome, {user.email}!</span>}
        <span onClick={handleSwitcher} className={css(style.userLoginButton)}>
          {user.active ? "LOGOUT" : "LOGIN"}
        </span>
      </div> }

      {data && <h1 className={css(style.headline)}>{data.copy.headline}</h1>}
      {data && <h2 className={css(style.subHeadline)}>{data.copy.subheadline}</h2>}
      {data && <p className={css(style.emailCTA)}>{data.copy.email_cta}</p>}

      {
        loginVisible &&
        <div className={css(style.loginContainer)}>
          <div className={css(style.loginColumn)}>
            <label className={css(style.loginLabel)}>Email*</label>
            <input name="email" onChange={handleFormChange} className={css(style.input)} type="email" placeholder="Enter Email"/>
            {formEmailError && <span className={css(style.errorMessage)}>{data.copy.email_error}</span>}
            <div className={css(style.checkbox)}>
              <input onChange={handleFormChange} type="checkbox" id="terms" name="terms" value="terms" defaultChecked={data.terms.settings.preChecked === "true"}/>
              <label className={css(style.checkboxLabel)} htmlFor="terms" dangerouslySetInnerHTML={{__html: data.terms.copy.copy}}/>
            </div>
            {formTermsError && <span className={css(style.errorMessage)}>{data.terms.copy.error}</span>}
            <span className={css(style.requiredFields)}>* All fields are required</span>
          </div>
          <div className={css(style.loginColumn)}>
            <button onClick={handleLogin} className={css(style.loginButton)}>{data.buttons.email.copy}</button>
            <div className={css(style.checkbox)}>
              <input onChange={handleFormChange} type="checkbox" id="optIn" name="optIn" value="optIn" />
              <label className={css(style.checkboxLabel)} htmlFor="optIn" dangerouslySetInnerHTML={{__html: data.optIn.copy.copy}}/>
            </div>
            {formOptInError && <span className={css(style.errorMessage)}>{data.optIn.copy.error}</span>}
          </div>
        </div>
      }
    </section>
  );
}

export default Login;
