import merge from 'lodash/merge';
import { StyleSheet } from 'aphrodite/no-important';

import { IGenericObject } from '../../models/base';
import { children, generateCmsStyles, media } from '../../utils/styles';

export const styleSheet: any = (styles: IGenericObject) => {
  const baseStyles: any = {
    container: {
      background: "linear-gradient(180deg, rgb(14 1 25) 0%, rgb(95 62 138) 100%)",
      padding: "50px 30px 20px 30px",
      position: "relative",
      maxWidth: "975px",
      width: "100%",
      margin: "0 auto"
    },
    headline: {
      textAlign: "center",
      textTransform: "uppercase",
      fontWeight: "500",
      fontSize: "35px"
    },
    subHeadline: {
      textAlign: "center",
      fontWeight: "300",
      fontSize: "19px"
    }
  };

  const cmsStyles: any = {
    ...generateCmsStyles(styles),
  };

  return StyleSheet.create(merge(baseStyles, cmsStyles));
};

