import { IGenericObject } from '../models/base';

export const breakpoints = {
  mobile: 320,
  phablet: 550,
  tablet: 750,
  desktop: 980,
};

export const media = {
  mobile: `@media (min-width: ${breakpoints.mobile}px)`,
  phablet: `@media (min-width: ${breakpoints.phablet}px)`,
  tablet: `@media (min-width: ${breakpoints.tablet}px)`,
  desktop: `@media (min-width: ${breakpoints.desktop}px)`,
  portaitAndMobile: `@media (min-width: ${breakpoints.mobile}px) and (orientation: portrait)`,
  landscapeAndMobile: `@media (min-width: ${breakpoints.mobile}px) and (orientation: landscape)`,
} as IGenericObject;


export const children = (child:string) => {
  return `:nth-child(1n) ${child}`
}

// Normalize Styles
export function normalizeStyles(obj: IGenericObject) {
  const pseudoSelectors = ['hover', 'disabled', 'focus', 'active'];

  if (isObject(obj)) {
    const n: IGenericObject = {};

    Object.keys(obj).forEach((key) => {
      if (key === 'background_image' && obj[key]) {
        if (obj[key] !== 'none' && obj[key].indexOf('url(') === -1) {
          obj[key] = `url(${obj[key]})`;
        }
      }

      if (pseudoSelectors.some((selector) => key.includes(selector))) {
        const pseudoKey = key.replace('_', ':');
        obj[`:${pseudoKey}`] = obj[key];
        key = `:${pseudoKey}`;
      }

      if (obj[key] !== '') {
        n[toCamel(key)] = normalizeStyles(obj[key]);
      }
    });

    return n;
  } else if (isArray(obj)) {
    return obj.map((i: IGenericObject) => {
      return normalizeStyles(i);
    });
  }

  return obj;
}

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const toCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const insertFonts = (url: string) => {
  if (!url) {
    return;
  }
  const fontLink = document.createElement('link');
  document.head.appendChild(fontLink);
  fontLink.rel = 'stylesheet';
  fontLink.href = url;
};

export const generateCmsStyles = (styles: IGenericObject) => {
  let obj: IGenericObject = {};

  for (const element in styles) {
    obj[element] = {};
    Object.keys(breakpoints).forEach((breakpoint) => {
      if (styles[element][breakpoint]) {
        obj[element][`${media[breakpoint]}`] = { ...styles[element][breakpoint] };
      } else {
        if (breakpoint === 'mobile') {
          obj[element] = { ...styles[element] };
        }
      }
    });
  }

  return obj;
};
