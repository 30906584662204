import React from "react"

export interface userState {
  active: boolean;
  votes: Array<any> | undefined;
  email: string;
  loginSection?: boolean | undefined;
}

export const userConfig = {
  loggedIn: {
    active: true,
    votes: {},
    email: "",
    loginSection: undefined
  },
  loggedOut: {
    active: false,
    votes: {},
    email: "",
    loginSection: undefined
  }
}

export const UserContext = React.createContext(userConfig.loggedOut);