import React, { useEffect, useState } from 'react';
import * as Pym from 'pym.js';
import useCMS from '@telescope/react-hooks/useCMS';

import { ICmsData } from './models/api';
import { DEFAULT_POLL_RATE, DEFAULT_STYLES_WID, DEFAULT_WID, GA_EVENTS } from './utils/constants';
import { getQSPs } from './utils/helpers';

import Header from './components/header/header';
import Footer from './components/footer/footer';
import Login from './components/login/login';
import Winner from './components/winner/winner';
import Grid from './components/grid/grid';
import { userConfig, UserContext } from './utils/context';
import { apiService } from "./utils/api";
import {
  addClickListener,
  initializeGoogleAnalytics,
  isInitialized,
  removeClickListener, trackGoogleEvent
} from './utils/google-helpers';
import Closed from './components/closed/closed';

function App() {
  const { sid, wid = DEFAULT_WID, childId } = getQSPs();

  const [cmsData] =
    useCMS<ICmsData>(`${wid}`, { sid, pollingFrequency: DEFAULT_POLL_RATE, env: '' });
  const [stylesCms] =
    useCMS<ICmsData>(`${DEFAULT_STYLES_WID}`, { pollingFrequency: DEFAULT_POLL_RATE });
  const [userContext, setUserContext] = useState(userConfig.loggedIn); // This is the default context.
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    if (cmsData && cmsData.settings) {
      apiService.setConfiguration(cmsData.settings);
      setAppLoaded(true);
    }
  }, [cmsData]);

  useEffect(() => {
    addClickListener();

    const { category, action, label } = GA_EVENTS.INIT_APP;
    trackGoogleEvent(category, action, label);

    const parentId = childId || 'telescope-child';
    const pymChild = new Pym.Child({
      polling: 500,
      id: parentId,
    });

    return () => {
      removeClickListener();
    };
  }, [cmsData?.settings?.google_analytics]);

  if (cmsData?.settings?.google_analytics && !isInitialized()) {
    initializeGoogleAnalytics(cmsData.settings.google_analytics);
  }


  return (
    <UserContext.Provider value={userContext}>
      <div>
        <Header data={cmsData?.text.header} styles={stylesCms?.text.header}/>
        {cmsData?.settings.window_status === "0" && <Closed data={cmsData?.text.closed} styles={stylesCms?.text.closed}></Closed>}
        {cmsData?.settings.window_status !== "0" && 
          <div>
            <div className="app-container">
              <Login appLoaded={appLoaded} updateUser={setUserContext} data={cmsData?.text.login} styles={stylesCms?.text.login}/>
              <Winner data={cmsData?.text.winner} styles={stylesCms?.text.winner}/>
              <Grid cards={cmsData?.data} updateUser={setUserContext} data={cmsData?.text.grid} nominee={cmsData?.text.nominee} styles={stylesCms?.text.grid}/>
            </div>
            <Footer data={cmsData?.text.footer} styles={stylesCms?.text.footer}/>
          </div>
        }
      </div>
    </UserContext.Provider>
  );
}

export default App;
