import merge from 'lodash/merge';
import { StyleSheet } from 'aphrodite/no-important';

import { IGenericObject } from '../../models/base';
import { children, generateCmsStyles, media } from '../../utils/styles';

export const styleSheet: any = (styles: IGenericObject) => {
  const baseStyles: any = {
    container: {
      background: "linear-gradient(180deg, rgb(14 1 25) 0%, rgb(95 62 138) 100%)",
      padding: "30px 30px 20px 30px",
      position: "relative"
    },
    headline: {
      color: "#F2FCFF",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 0,

      [media.phablet]: {
        fontSize: 25
      },

      [media.desktop]: {
        fontSize: 30
      }
    },
    subHeadline: {
      color: "#F2FCFF",
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: 16,
      fontWeight: 500,
      margin: 0,

      [media.phablet]: {
        fontSize: 25
      },

      [media.desktop]: {
        fontSize: 30
      }
    },
    emailCTA: {
      color: "#F2FCFF",
      textAlign: "center",
      fontSize: 13,
      fontWeight: 300,
      marginTop: 0,

      [media.desktop]: {
        fontSize: 16
      }
    },
    requiredFields: {
      fontWeight: 300,
      color: "#F2FCFF",
      fontSize: 13,
      marginTop: "10px",
      display: "block"
    },
    loginContainer: {
      display: "flex",
      width: "100%",
      maxWidth: "680px",
      justifyContent: "space-between",
      margin: "0 auto"
    },
    loginColumn: {
      width: "45%",
      margin: "0 5%"
    },
    loginLabel: {
      display: "block",
      width: "100%",
      marginBottom: "10px",
      color: "#F2FCFF",
      fontSize: 13,
      fontWeight: 300
    },
    input: {
      display: "block",
      width: "95%",
      padding: "10px 10px",
      background: "transparent",
      border: "1px solid #fff",
      color: "#F2FCFF",
      boxSizing: "border-box",
      height: 41,
      fontWeight: 300,
      '::placeholder': {
        color: "#F2FCFF"
      },
    },
    checkbox: {
      display: "flex",
      marginTop: "10px",
      color: "#F2FCFF"
    },
    checkboxLabel: {
      marginLeft: "5px",
      fontWeight: "200",
      fontSize: 13,
      [children('a')]: {
        color: "inherit"
      }
    },
    errorMessage: {
      color: "red",
      display: "block",
      marginTop: "8px",
      fontSize: "12px"
    },
    loginButton: {
      cursor: "pointer",
      display: "block",
      background: "#29094A",
      width: "100%",
      fontWeight: 300,
      color: "#F2FCFF",
      border: "none",
      padding: "9px 0px",
      marginTop: "27px",
      fontSize: "18px",
      textTransform: "uppercase",
      ':disabled': {
        cursor: "default"
      }
    },
    userContainer: {
      position: "absolute",
      top: "20px",
      right: "20px",
      color: "#F2FCFF",
    },
    userWelcome: {
      fontSize: 14,
      fontWeight: 500
    },
    userLoginButton: {
      fontSize: "14px",
      marginLeft: "10px",
      textDecoration: "underline",
      cursor: "pointer"
    }
  }

  const cmsStyles: any = {
    ...generateCmsStyles(styles),
  };

  return StyleSheet.create(merge(baseStyles, cmsStyles));
};

