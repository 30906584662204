import React from 'react';
import { css } from 'aphrodite/no-important';

import { styleSheet } from './styles';

export interface headerInterface {
  data: any;
  styles: any;
}

const Header = (props: headerInterface) => {
  const { data, styles } = props;
  const style = styleSheet(styles);

  return (
    <header className={css(style.banner)}>
      {data && styles && <img className={css(style.image_desktop)} src={styles.image_desktop} alt="Header image" />}
      {data && styles && <img className={css(style.image_tablet)} src={styles.image_tablet} alt="Header image" />}
      {data && styles && <img className={css(style.image_mobile)} src={styles.image_mobile} alt="Header image" />}
    </header>
  );
}

export default Header;
