import React from 'react';
import { css } from 'aphrodite/no-important';

import { styleSheet } from './styles';

export interface winnerInterface {
	data: any;
	styles: any;
}

const Winner = ( props: winnerInterface ) => {
	const {data, styles} = props;
	const style = styleSheet( styles );

	if ( data?.settings.display === "true" ) {
		return (
			<section className={css(style.winner)}>
				<h1 className={css(style.headline)} dangerouslySetInnerHTML={{__html: data.copy.headline}}/>
				<div className={css(style.card)}>
					<div className={css(style.image)}>
						<img src={data.image} alt={data.copy.name}/>
					</div>
					<div className={css(style.name)}>
						<p className={css(style.characterName)} dangerouslySetInnerHTML={{__html: data.copy.character}}/>
						<p className={css(style.winnerName)} dangerouslySetInnerHTML={{__html: data.copy.name}}/>
					</div>
					<a className={css(style.button)} target='_blank' href={data.button.link}>{data.button.copy}</a>
				</div>
			</section>
		);
	} else {
		return ( <></> );
	}




};

export default Winner;
