import { BASE_SECRET_KEY } from "./constants";
import crypto from 'crypto';

class Api {
  private api_url = "https://voteapi.votenow.tv/s2/vote";
  private api_key: string | undefined;
  private api_secret: string | undefined;

  public setConfiguration(config: any): void {
    this.api_key = config.apiKey;
    this.api_secret = config.secretKey;
  }

  public async getVotes(userId: string): Promise<any> {
    let params = "apiKey=" + this.api_key;
    params += "&user_id=" + userId;
    params += "&method=email";
    params += "&action_type=get";
    params += "&timestamp=" + Math.round(new Date().getTime() / 1000).toString();

    const url = `${this.api_url}?${params}`;
    const key = this.api_secret + BASE_SECRET_KEY;
    const signature = this.generateHmac(params, key);
    const encodedSignature = encodeURIComponent(signature);

    const response: any = await fetch(url, {
      method: "post",
      headers: {
        "Authorization": signature,
        'Content-Type': 'text/plain',
      },
      body: 'Authorization=' + encodedSignature
    });
    return response.json();
  }

  public async sendVote(id: string, vote: string, user_id: string): Promise<boolean> {
    let params = "apiKey=" + this.api_key;
    params += "&user_id=" + user_id;
    params += "&v=" + id;
    params += "&method=email";
    params += "&action_type=vote";
    params += "&timestamp=" + Math.round(new Date().getTime() / 1000).toString();

    const url = `${this.api_url}?${params}`;
    const key = this.api_secret + BASE_SECRET_KEY;
    const signature = this.generateHmac(params, key);
    const encodedSignature = encodeURIComponent(signature);
   
    const response: any = await fetch(url, {
      method: "post",
      headers: {
        "Authorization": signature,
        'Content-Type': 'text/plain',
      },
      body: 'Authorization=' + encodedSignature
    });

    return true;
  }

  private generateHmac(data: string, key: string): string {
    data = data.replace(/ /g, "%20");
    const encoding = 'base64';
    const algorithm = 'sha256';
    return crypto.createHmac(algorithm, key).update(data).digest(encoding);
  }
}

export const apiService = new Api();